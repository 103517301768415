<template>
  <div class="bg-white border-red p-2 p-lg-3">
    <div>
      <b-table
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(image_url)="data">
          <div class="d-flex justify-content-center" v-if="data.item.image_url">
            <b-img
              class="product-image"
              :src="data.item.image_url"
              fluid
            ></b-img>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-img
              class="product-image"
              src="/img/default-placeholder.png"
              fluid
            ></b-img>
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <div class="text-left">
            <router-link :to="'/product/detail/' + data.item.product_id">
              <b-button variant="link" class="text-left name-link pl-0">
                {{ data.item.name ? data.item.name : "-" }}
              </b-button>
            </router-link>
            <div>
              {{ data.item.article_no }}
              <span class="article-type">{{ data.item.article_type }}</span>
            </div>
          </div>
        </template>
        <template v-slot:cell(price)="data">
          <div>{{ data.item.price | numeral("0,0.00") }}</div>
        </template>
        <template v-slot:cell(barcode)="data">
          <div>{{ data.item.barcode ? data.item.barcode : "-" }}</div>
        </template>
        <template v-slot:cell(status)="data">
          <div v-if="data.item.status === 1" class="text-success">Active</div>
          <div v-else class="text-error">Inactive</div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <Pagination
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
      @handleChangeTake="handleChangeTake"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
      showings: this.showing,
    };
  },
  methods: {
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showings = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showings = value * this.filter.take - this.filter.take;
        this.toShow = this.showings + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
    showDetail(item) {
      this.$emit("showDetail", item.product_id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
